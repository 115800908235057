<template>
  <div class="inviteRegister">
    <div class="hang">
      <div class="top-slider">
        <span>好友{{inviterName}}邀请你加入速派赚钱</span>
      </div>
    </div>
    <div class="form">
      <div class="form-item">
        <input class="input" type="tel" v-model.trim="phone" placeholder="请输入手机号">
      </div>
      <div class="form-item">
        <input class="input" type="tel" v-model.trim="code" placeholder="请输入验证码">
        <button class="btn-code" :disabled="btnDisabled"  @click="getCode">{{codeBtnText}}</button>
      </div>
      <div class="btn-submit" @click="submit">立即注册</div>
    </div>
  </div>
</template>
<script>
import validate from '@/common/validate.js';
import {
  SendMobileCode,
  UserActivityRegister,
  GetInviterInfo,
} from "@/common/api.js";
export default {
  data(){
    return{
      inviterName:'',
      phone:'',
      code:'',
      codeBtnText:'获取验证码',
      btnDisabled:false,
      timer:null,
      userId:0,
      userType:1
    }
  },
  created() {
    this.userId=Number(this.$route.query.userId) || 0;
    this.userType=Number(this.$route.query.userType) || 1;
    this.getInviterInfo();
  },
  methods: {
    getInviterInfo(){
      GetInviterInfo({
        UserId:this.userId,
        UserType:this.userType,
      }).then(r=>{
        if(r.Result==1){
          this.inviterName=r.Data.TrueName;
        }
      })
    },
    //获取验证码
    getCode(){
      if(this.phone.trim()===''){
        this.$toast('请输入手机号！');
        return;
      }
      if (!validate.phone(this.phone)) {
          this.$toast('手机号格式不正确');
          return;
      }
      SendMobileCode({
        phone:this.phone,
        codeType:112,
        imgCode:'',
        imgCodeToken:'',
        mobileMsg:'',
      }).then(r=>{
        if(r.Result==1){
          this.$toast.success(r.Message);
          let time=60;
          this.btnDisabled=true;
          this.timer=setInterval(() => {
            time--;
            this.codeBtnText=time+'s';
            if(time<=0){
              time=60;
              this.btnDisabled=false;
              this.codeBtnText='获取验证码';
              clearInterval(this.timer);
            }
          }, 1000);
        }else if(r.Result==602 ){
          var that=this;
          this.$notify({
            message:r.Message,
            onClose(){
              that.$router.push('/personRegister');
            }
          });
        }else{
          this.$notify(r.Message);
        }
      })
      
    },
    //提交
    submit(){
      if(this.phone===''){
        this.$toast('请输入手机号！');
        return;
      }
      if (!validate.phone(this.phone)) {
          this.$toast('手机号格式不正确');
          return;
      }
      UserActivityRegister({
        InviteUserId:this.userId,
        InviteUserType:this.userType,
        HandSet:this.phone,
        ValidateCode:this.code,
        AppId:101101,
      }).then(r=>{
        if(r.Result==1){
          console.log(r);
          this.$toast('注册成功');
          window.location.href="https://www.insupai.com/download/download.html";
        }else{
          this.$toast(r.Message);
        }
      })
    }
  },
}
</script>
<style lang="scss">
@import '~@/style/base.scss';
.inviteRegister{
  width: 10rem;
  min-height: 100vh;
  margin: 0 auto; 
  position: relative;
  overflow: hidden;
  @include bg('~static/h5/invite/reg_newbg.png');
  background-size: contain;
  background-position: center top;
  background-color: #002354;
  .hang{
    width: px2rem(550px);
    height: px2rem(120px);
    @include bg('~static/h5/invite/hang.png');
    background-size: contain;
    margin: 0 auto;
    padding-top: px2rem(40px);
    .top-slider{
      color: #fff;
      width: px2rem(520px);
      text-align: center;
      font-size: 14px;
      line-height: px2rem(64px);
      white-space: nowrap;
      overflow: hidden;
      margin: 0 auto;
    }
  }
  .form{
    position: absolute;
    left: 50%;
    bottom: px2rem(65px);
    transform: translateX(-50%);
    width: px2rem(694px);
    padding: px2rem(64px) px2rem(48px) px2rem(72px);
    background-color: #fff;
    border-radius: 10px;
    .form-item{
      margin-bottom: px2rem(62px);
      height: px2rem(84px);
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
    }
    .input{
      width: 100px;
      flex: 1;
      font-size: 14px;
      color: #333;
      line-height: 32px;
    }
    .btn-code{
      width: px2rem(200px);
      height: px2rem(64px);
      border-radius: 64px;
      background-color: #D5D5D5;
      text-align: center;
      font-size: 14px;
      color: #333;
    }
    .btn-submit{
      display: block;
      width: 100%;
      height:  px2rem(88px);
      line-height:  px2rem(88px);
      border-radius: 88px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      background: linear-gradient(0deg, #d22683 , #3a2b92);
    }
  }
}
</style>